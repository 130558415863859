#chooseADeliveryDate button.ui.red.active {
  background: #AC1A2B!important;
}
#chooseADeliveryDate button.ui.red.basic {
  box-shadow: 0 0 0 1px #AC1A2B inset!important;
  color: #AC1A2B !important;
}
#datePicker {
  width: 180px;
  margin-bottom: 8px;
}
.bottomSpacer {
  margin-bottom: 40px;
}
