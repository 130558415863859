/*
  green - #37A9AF
  red - #AC1925
*/

html, body, #root, .app, .pushable, .pusher, .appBody {
  min-height: 100vh;
  font: 'Lato';
}

/* .loginSegment {
  margin: 40px auto auto auto!important;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.loginSegment form {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loginSegment form button {
  max-width: 100px;
  align-self: center;
} */

.sidebarPushable, .pusher {
  transform: none !important;
}

.pusher {
  position: static !important;
}

.pusher.dimmed::after {
  position: fixed !important;
}

.appMenu {
  height: 44px !important;
  justify-content: space-between;
  font-size: 16px;

}
.appMenu .donatosButton {
  color: #fff;
  font-size: 16px;
}
.appMenu .navDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #AC1925;
  font-size: 16px;
}
.appMenu .navDiv .navContainer {
  display: flex;
  font-size: 16px;
}
.appMenu .navDiv .navContainer .headerItem {
  color: #fff;
  font-size: 16px;
}
.appMenu .navDiv .signOutDiv {
  font-size: 16px;
}
.appMenu button {
  border-radius: 0px!important;
  margin-right: 0px!important;
  height: 100%;
  font-size: 16px;
}

.appBody {
  padding-top: 44px;
  font: 'Lato';
  font-size: 16px;
}

.menuSidebar {
  width: 200px !important;
  position: fixed !important;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1001 !important;
}
.menuSidebar::-webkit-scrollbar {
  display: none;
}

.menuSidebar .divider {
  margin: 0;
}
.menuSidebar .sideBarItem {
  display: flex!important;
  flex-direction: row!important;
  align-items: center;
}
#donatosMenu > div.item.sideBarItem > h5 {
  margin-top: 0px;
}
#donatosMenu > .sideBarHeader {
  font-weight: bold;
  color: #AC1925;
  font-size: 16px;
}
#donatosMenu > a {
  padding: 9px;
  font-size: 16px;
}
#donatosMenu > div.disabled.item.sideBarItem > i, #donatosMenu > a.item.sideBarItem > i {
  margin: 0 15px 0 0 !important;
  font-size: 16px;
}
#donatosMenu .sideBarItem i {
  font-size: 16px!important;
}
#donatosMenu .sideBarItem h5 {
  font-size: 16px!important;
  margin-top: 0px;
  font-weight: normal;
}

.infoDiv {
  margin-bottom: 26px;
}
.infoHeader {
  margin-bottom: 1px;
}
.infoDetails {
  font-weight: normal;
  margin-top: 2px;
  margin-bottom: 2px;
}

.donatosButton {
  background-color: #37A9AF!important;
  color: #fff!important;
}

.menuButton {
  background-color: #AC1925!important;
  color: #fff!important;
}

#navMenuButton {
  font-size: 16px;
  display: flex;
}

.inActiveItem {
  color: #c1c1c1!important;
}

.activeItem {
  color: #37A9AF!important;
}

#gcAccordianIcon,#aiAccordianIcon,#gpAccordianIcon {
  margin: 0px 12px 0px 6px!important;
}

#accordionTitleIcon {
  margin: 0px 12px 0px 10px!important;
}

#accordionText {
  font-size: 16px!important;
}

#iconImage {
  margin: 2rem!important;
  border-width: medium!important;
  border-color: #252222!important;

}

.iconImage {
  margin: 2rem!important;
  border-width: medium!important;
  border-color: #252222!important;

}

#iconImageCard {
  margin: .5rem!important;
  display: inline-block!important;
}

.iconImageCard {
  margin: .5rem!important;
  display: inline-block!important;
}

#appleIconHolder1 {
  width: 87px!important;
  height: 87px!important;
}

.iconContainerDiv {
  background-color: #EEEEEE!important;
  border-radius: 6px;
  border-width: 4px;
  border-color: #333333;
  margin-top: 24px;
}

.previewCardDiv {
  width:325px;
  height:520px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
}

.chrome-picker {
  display: inline-block;
  margin-top: 6px;
}

.imageShadow {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
}

.iconImageFrame {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
  width: 87px;
  height: 87px;
}

.googleLogoFrame {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
  width: 200px;
  height: 200px;
}

.mainCardImageFrame {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
  width: 300px;
  height: 189px;
}

.previewLogoImage {
  height: 60px!important;
  width: auto!important;
  border-radius: 6px 0px 0px 0px!important;
}

#previewLogoImage {
  height: 60px!important;
  width: auto!important;
  border-radius: 6px 0px 0px 0px!important;
}

.logoCardImageFrame {
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px; */
  width: 190px!important;
  height: 60px!important;
  border-radius: 6px 0px 0px 0px!important;
}

#logoCardImageFrame {
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px; */
  width: 190px!important;
  height: 60px!important;
  border-radius: 6px 0px 0px 0px!important;
}

.stripCardImageFrame {
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px; */
  width: 450px;
  height: 124px;
}

#stripCardImageFrame {
  /* box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px; */
  width: 450px;
  height: 124px;
}

.previewIconDiv {
  display: inline-block;
  width: 385px;
  height: 120px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 16px;
  background-color: #FDFDFD;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
}

.previewIconIcon {
  text-align: left;
  width: 22px;
  height: 20px;
  float: left;
  vertical-align: middle;
  margin-bottom: 10px;
}

.previewIconTitle {
  text-align: left;
  width: 290px;
  height: 20px;
  float: left;
  margin-left: 8px;
  vertical-align: middle;
  font-size: 14px;
  margin-bottom: 10px;
  color: #AAAAAA;
}

.previewIconWhen {
  text-align: right;
  width: 30px;
  height: 20px;
  float: left;
  vertical-align: middle;
  font-size: 14px;
  margin-bottom: 10px;
  color: #AAAAAA;
}

.previewIconText {
  text-align: left;
  vertical-align: bottom;
  font-size: 16px;
}

.previewGoogleCardDiv {
  width:325px;
  height:520px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
}

.googlePayLogoDiv {
  padding-top: 40px;
  padding-bottom: 10px;
}

.googlePayMerchantName {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: bold;
}

.googlePayBalance {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.googlePayLogo {
  width: 325px;
  height: 80px;
}

#googlePayLogo {
  width: 80px;
  height: 80px;
}

.googlePayHeroImage {
  width: 344px;
  height: 115px;
}

#googlePayHeroImage {
  width: 344px;
  height: 115px;
}

.googleHeroImageShadow {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
  width: 344px;
  height: 115px;
}

.previewGoogleLogoImage {
  height: 80px!important;
  width: auto!important;
}

#previewGoogleLogoImage {
  height: 80px!important;
  width: auto!important;
}

.googleCardImageFrame {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px, rgba(0, 0, 0, 0.5) 0px 4px 8px;
  /* width: 300px;
  height: 300px; */
}

/* ----- Promotion Design Components ----- */

.uploadSegment {
  border-style: dashed;
  border-color: #00b5ad;
  border-radius: 6px;
  border-width: 1.75px;
  padding: 10px;
  background-color: #EEEEEE;
  align-items: center;
  align-content: center;
  text-align: center;
  min-height: 150px;
}
.uploadSegmentRowDiv {
  overflow: auto;
  padding: 10px 10px 10px 10px;
  vertical-align: middle;
}
.uploadSegmentColumnDiv {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  height: 100%;
  float: left;
  align: left;
  vertical-align: middle;
  padding: 4px 4px 4px 4px;
}
.uploadFileDiv {
  width: 358px;
  border-style: solid;
  border-color: #00b5ad;
  border-radius: 6px;
  border-width: 1px;
  padding: 10px;
  margin: auto;
  background-color: #FFFFFF;
}
.uploadSegment .uploadButtonDiv {
  margin-left: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}
.uploadSegmentRowButton {
  color: #00b5ad;
  width: 375px;
  padding: 5px;
  margin: 35px auto auto auto;
  cursor: pointer;
}
.uploadSegmentButton {
  border-style: dashed;
  border-color: #00b5ad;
  border-radius: 6px;
  border-width: 1.75px;
  padding: 10px;
  background-color: #EEEEEE;
  min-height: 150px;
  cursor: pointer;
}
.uploadSegmentExpireDate {
  border-style: dashed;
  border-color: #00b5ad;
  border-radius: 6px;
  border-width: 1.75px;
  padding: 38px 10px 10px 10px;
  background-color: rgba(0,181,173, 0.05);
  min-height: 150px;
}

.promotionResultsClass {
  font-size: 18px;
  color: #4A4A4A;
}
.promotionResultsHeaderClass {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #999999;
  padding: 0px 0px 12px 0px;
  border-width: 0px 0px 1.5px 0px;
  border-color: #CCCCCC;
  border-style: solid;
  margin-right: 5px;
  margin-left: 5px;
}
.promotionStatsContainerDiv {
  flex-direction: row;
  overflow: auto;
  align-items: center;
}
.promotionStatsRowDiv {
  flex-wrap: wrap;
  overflow: auto;
  padding: 5px 10px 5px 10px;
  vertical-align:'middle';
  align-items: center;
}
.promotionStatsColumnDiv {
  display: inline-block;
  text-align: left;
  font-size: 18px;
  color: #4A4A4A;
  width: 25%;
  float:'left';
  align:'left';
  vertical-align:'middle';
  padding: 4px 4px 4px 4px;
}
.promotionActiveCodeColumn1Div {
  display: inline-block;
  text-align: left;
  font-size: 18px;
  color: #4A4A4A;
  width: 25%;
  float:'left';
  align:'left';
  vertical-align:'middle';
  padding: 4px 4px 4px 4px;
}
.promotionActiveCodeColumn2Div {
  display: inline-block;
  text-align: left;
  font-size: 18px;
  color: #4A4A4A;
  width: 25%;
  float:'left';
  align:'left';
  vertical-align:'middle';
  padding: 4px 4px 4px 4px;
}
.promotionActiveCodeColumn3Div {
  display: inline-block;
  text-align: right;
  font-size: 14px;
  color: #4A4A4A;
  width: 50%;
  float:'left';
  align:'left';
  vertical-align:'middle';
  padding: 4px 4px 4px 4px;
}
.promotionActiveCodeLowInput {
  width: 50px!important;
  text-align: center;
}
.promotionActiveCodeEmailInput {
  width: 180px!important;
}

.promotionFileListClassDiv {
  overflow: auto;
  width: 95%;
  margin: auto;
}
.promotionFileListClassRow {
  flex-wrap: wrap;
  overflow: auto;
  padding: 4px 10px 0px 10px;
  width: 100%;
}
.promotionFileListClassColumn {
  display: inline-block;
  text-align: left;
  font-size: 12px;
  color: #4A4A4A;
  width: 25%;
  float:'left';
  align:'left';
  vertical-align:'middle';
  padding: 0px 0px 0px 0px;

}
.promotionFileListClassRow .promotionFileListFile {
  width: 30%;
}
.promotionFileListClassRow .promotionFileListDate {
  width: 30%;
}
.promotionFileListClassRow .promotionFileListUser {
  width: 25%;
}
.promotionFileListClassRow .promotionFileListRecords {
  width: 15%;
  text-align: right;
}


/* ----- React Cropper fixes ----- */

.cropper-segment
div.ReactCrop {
  min-height: 222px;
  background-image:
    linear-gradient(45deg, #ddd 25%, transparent 25%),
    linear-gradient(-45deg, #ddd 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ddd 75%),
    linear-gradient(-45deg, transparent 75%, #ddd 75%);
  background-size:16px 16px;
  background-position:0 0, 0 8px, 8px -8px, -8px 0px
}

div.ReactCrop > div:first-child {
  min-height: inherit;
  position: relative;
}

div.ReactCrop div:first-child:before {
  content: '';
  width: 0;
  min-height: inherit;
  display: inline-block;
  vertical-align: middle;
}

div.ReactCrop div:first-child img {
  display: inline-block;
  vertical-align: middle;
}


/* ----- Date Picker Classes ----- */

#startDateLabelClass, #endDateLabelClass {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px 0px 0px 5px;
  padding: 12px 0px 0px 12px!important;
  margin: 0px 0px 0px 0px!important;
  height: 40px;
  width: 134px;
}
#startDatePickerClass, #endDatePickerClass {
  display: inline-block;
  vertical-align: middle;
  font-size:16px;
  color: #333333;
  border-radius: 0px 5px 5px 0px;
  padding: 6px 4px 4px 14px!important;
  margin: 0px 4px 0px 0px!important;
  border: 1px solid rgba(34,36,38,.15);
  height: 40px;
}



/* ----- Purchased Cards Component ----- */

.purchasedCardTableContainer {
  margin-bottom: 30px;
}

.purchasedFilterContainer {
  background-color: #F9F9F9;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between
}
.purchasedFilterButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 0px 10px 0px;
}
.purchasedDateRangeAndMonetaryAmounts {
  display: flex;
  flex-direction: column;
}
.purchasedDatepicker {
  color: #5C5D5D;
  font-size:14px;
  width: 108px;
  height: 36px;
  border-radius: 4px;
  padding: 6px 4px 4px 14px!important;
  margin: 0px 5px 0px 0px!important;
  border: 1px solid #DFE3E9;
}
.purchasedDateRanges {
  display: flex;
  margin-bottom: 8px;
}
.purchasedDateRanges div:nth-child(1) div input {
  height: 38px!important;
}
.purchasedMonetaryAmounts .label {
  height: 38px;
  font-weight: normal;
  color: #454545;
  font-family: Lato;
  font-size: 14px;
  padding-top: 11px!important;
  padding-left: 7px;
  padding-right: 7px;
  /* padding-right: 0px; */
  /* padding-left: 5px; */
  /* border-right: 0px!important; */
}
.purchasedMonetaryAmounts .input .label {
  width: 55px;
}
.purchasedMonetaryAmounts div input {
  padding-left: 5px!important;
  padding-right: 5px!important;
}
.purchasedMonetaryAmounts div > input[type="text"] {
  width: 53px;
}
.purchasedMonetaryAmounts div:nth-child(2) > input[type="text"] {
  /* width: 74px; */
}
.purchasedMonetaryAmounts input {
  padding: auto 10px!important;
  height: 38px;
}
.purchasedDateRanges div:nth-child(1) div input, .purchasedMonetaryAmounts div:nth-child(1) input[type="text"] {
  margin-right: 8px!important;
}
.purchasedFilterButtonsAndSearch {
  display: flex;
  flex-direction: column;
}
.purchasedFilterButtons .buttons {
  /* margin-right: 18px; */
  border-radius: 6px;
}
.purchasedFilterButtons .buttons .activeFilter {
  background-color: #faf5f1;
  color: #000;
}
.purchasedFilterButtons .buttons .inactiveFilter {
  background-color: #fff;
  color: #b8b4b2;
}
.purchasedFilterButtons .buttons .button {
  border: 1px solid #E5E5E5;
  font-family: Lato;
  font-size: 14px;
  display: flex;
  height: 36px;

}
.purchasedFilterButtons .ui.buttons .button:first-child {
  border-left: 1px solid #e5e5e5!important;
}
.purchasedFilterButtons .buttons .button span {
  margin-right: 4px;
  border-radius: 8px;
  height: 16px;
  font-size: 11px;
  color: #4A4A4A;
  font-family: Lato;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  padding: 1px 2px;
}
.purchasedFilterButtons .buttons .button p {
  margin-top: -2px;
}
.purchasedFilterButtons .buttons .button span:nth-child(1) {
  background-color: #D2D2D2;
}
.purchasedFilterButtons .buttons .button:nth-child(1) {
  height: 36px;
}
.purchasedFilterButtonsAndSearch .input input {
  /* margin-right: 4px!important; */
  border-radius: 6px;
  height: 36px;
}
.purchasedFilterContainer {
  display: flex;
  align-items: center;
}
.purchasedFilterContainer #download {
  height: 36px;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
}
.purchasedFilterContainer #download .icon {
  margin-right: 3px;
}


.purchasedCardTableHeaderRow, #purchasedCardTableHeaderRow, thead {
  font-weight: 600;
  border-width: 0px 0px 1.5px 0px!important;
  border-color: #CCCCCC;
  border-style: solid;
}

#purchasedPopup {
  padding: 0px;
  background-color: #FFFFFF;
  border: 1px solid #DBDBDB;
  border-radius: 6px;
  /* width: 324px!important; */
  /* height: 190px!important; */
}
#purchasedPopup .popupHeaderContainer {
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  padding: 6px 15px;
}
#purchasedPopup .popupHeaderContainer span {
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
}
#popupOptions {
  padding: 7px 20px;
  margin: 0px;
  color: #4A4A4A;
  font-size: 14px;
  line-height: 26px;
  text-align: left;
}
#popupOptions .row {
  padding-top: 0px;
  padding-bottom: 4px;
}
#popupOptions .column {
  padding: 0px;
}
#popupOptions label {
  padding-left: 18px;
  color: #4A4A4A;
  font-size: 14px!important;
  /* line-height: 26px; */
}


#purchasedDetails {
  padding: 0px;
  margin: auto 40px;
}
.purchasedDetailsHeader {
  background-color: #F9F9F9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 17px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}
.purchasedDetailsHeader #detailCard {
  width: 94px;
  height: 60px;
  margin: 0px;
}
.purchasedDetailsHeader .detailColumn {
  display: flex;
  flex-direction: column;
}
.detailColumn span {
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  text-align: left;
}
.detailColumn span font {
  font-weight: bold;
  margin-right: 3px;
}
.detailColumn .columnHeader {
  font-weight: bold;
  color: black;
  margin-right: 5px;
}
.headerDivider {
  margin: -12px auto 0px!important;
}
.headerDivider .icon {
  background-color: white;
  font-size: 20px !important;
  border-radius: 100%;
}
.headerDivider .icon:hover {
  cursor: pointer;
}

.purchasedDetailsExpanded {
  background-color: #F9F9F9;
  padding: 8px 10px;
  display: flex;
  justify-content: space-around;
  margin-top: -7px;
  padding-top: 15px;
}
.expandedDetailsColumn, .expandedDetailsSection {
  display: flex;
  flex-direction: column;
}
.expandedDetailsColumn {
  max-width: 300px;
}
.expandedDetailsSection {
  margin-bottom: 12px;
}
.expandedDetailsSection span {
  color: #4A4A4A;
  font-family: Lato;
  font-size: 14px;
  text-align: left;
  margin: 1px 0px;
}
.expandedDetailsHeader {
  font-weight: bold;
}

.purchasedEventLog {
  padding: 5px 20px 20px;
}
.purchasedEventLog table {
  margin-top: 5px!important;
  margin-left: 10px!important;
  table-layout: fixed;
  width: 100%;
}

.purchasedEventLog table td {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}

.eventLog:hover {
  cursor: pointer;
}
.eventLogIcon {
  width: 30px;
}
.eventLogDate {
  width: 200px;
}
.eventLogStatus {
  font-weight: bold!important;
  font-style: italic!important;
  width: 70px;
}
.errorSpacing {
  color: rgb(251, 189, 8);
}

.purchasedDetailsFooter {
  background-color: #F9F9F9;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}
.purchasedDetailsFooter div {
  display: flex;
  justify-content: space-between;
}
.retryContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.retryLabel {
  width: 120px;
  margin-right: 15px;
  text-align: right;
  color: #37A9AF;
}
.retryInput {
  width: 200px;
  margin-right: 15px;
}
.retryButton {
  margin-right: 15px!important;
}



.selectedCardContainer {
  padding: 20px 0px 0px 0px;

}


/* ----- GIFT CARD STATS COMPONENT ----- */

.containerBodyDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0px 0px 0px 0px;
  background-color: #C9C9C9;
  min-height: calc(100vh - 44px);
}
.menuBarContainer {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  background-color: #FFFFFF;
  min-height: 44px;
  max-width: 1260px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.menuBarSubContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-bottom: 4px;
}
.menuBarTitle {
  padding: 0px 10px 0px 0px;
  font-size: 18px;
  font-style: italic;
  align-content: center;
  margin: auto 0 auto 0;
}
.menuBarButton {
  color: #37A9AF;
  font-size: 14px;
  text-align: center;
  width: 112px;
  height: 36px;
  border: 1px solid #37A9AF;
  border-radius: 4px;
  padding: 7px 0px 0px 0px;
  margin: 0px 5px 0px 0px;
  align-content: center;
  align-items: center;
  cursor: pointer;
}
#menuBarStartDatePicker, #menuBarEndDatePicker {
  color: #5C5D5D;
  font-size:14px;
  width: 130px;
  height: 36px;
  border-radius: 4px;
  padding: 6px 4px 4px 28px!important;
  margin: 0px 5px 0px 0px!important;
  border: 1px solid #DFE3E9; /* rgba(34,36,38,.15); */
}
.menuBarDropdown, #menuBarDropdown {
  align-self: flex-end;
  font-size: .9rem;
  min-height: 36px!important;
  width: 222px;
  padding: 12px 6px 6px 14px;
}.dropdown.icon {
  margin: 0px!important;
  padding: 0px!important;
  top: 11px!important;
  right: 10px!important;
}

.dropdownPaginator{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: 'center';
  font-size: 12px;
  height: 36px;
  color: #6D7278;
  background-color: #F3F3F3;
  margin: auto;
  padding: 12px 8px 0px 8px;
  border-top: 1px solid rgba(0,0,0,.05);
  border-bottom: 1px solid rgba(0,0,0,.05);
}
.dropdownPaginatorText {

}
.dropdownPaginatorIcon {
  padding: 0px 8px 0px 8px;
}

.statsContainer {
  padding: 20px auto;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
}
.graphContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  max-width: 1000px;
}
.salesVolumeContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #979797; /* rgba(34,36,38,.15); */
  border-radius: 7px 7px 7px 7px;
  height: auto;
  background-color: #FFFFFF;
  margin: 10px;
}
.salesVolumeNumbersRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #F3F3F3;
  border-radius: 7px 7px 0px 0px;
}
.salesVolumeStatsDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #979797; /* rgba(34,36,38,.15); */
  width: 25%;
  min-width: 190px;
  padding-top: 8px;
  padding-bottom: 10px;
}
.salesVolumeStatsDivRight {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #979797; /* rgba(34,36,38,.15); */
  width: 25%;
  min-width: 190px;
  padding-top: 8px;
  padding-bottom: 10px;
}
.salesVolumeNumber {
  color: #6D7278;
  font-size: 60px;
  font: 'Cabin';
  line-height: 72px;
  text-align: center;
}
.salesVolumeLabel {
  color: #454545;
  font: 'OpenSans';
  font-size: 11px;
  line-height: 13px;
  text-align: center;
}
.salesVolumeTitleRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.salesVolumeTitleText {
  color: #6D7278;
  font: 'Cabin';
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  padding: 10px 10px 10px 20px ;
}
.salesVolumeTitleButton{
  padding: 10px 20px 10px 20px ;

}
.salesVolumeGraphRow {
  display: flex;
  padding: 10px 20px 20px 20px ;
  flex-wrap: wrap;
  margin: auto;
}
.salesVolumeBottomContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
}
.salesRegionsDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 7px 7px 7px 7px;
  min-width: 418px;
  height: 451px;
  background-color: #FFFFFF;
  margin: 10px;
}
.salesAmountDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #979797;
  border-radius: 7px 7px 7px 7px;
  min-width: 418px;
  height: 451px;
  background-color: #FFFFFF;
  margin: 10px;
}
.salesTitleContainer {
  background-color: #F3F3F3;
  border-radius: 6px 6px 0 0;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #CCCCCC;
  height: 61px;
  color: #6D7278;
  font: 'Cabin';
  font-size: 24px;
  font-weight: bold;
  line-height: 29px;
  padding: 16px 0px 0px 20px;
}
.salesRegionGraphContainer {
  display: flex;
  /* text-align: center; */
  margin: auto;
}
.salesStateContainer {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #CCCCCC;
}
.salesStateDiv {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 12px 0px 12px 40px;
  color: #454545;
  font: 'OpenSans';
  font-size: 14px;
  line-height: 24px;
  border-right: 1px solid #CCCCCC;
}
.salesStateDivRight {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 12px 0px 12px 40px;
  color: #454545;
  font: 'OpenSans';
  font-size: 14px;
  line-height: 24px;
}
.cardContainer {
  display: flex;
  min-width: 261px;
  /* max-width: 300px; */
  /* flex-grow: 1; */
}
.cardContainerColumn {
  display: flex;
  flex-direction: column;
  border: 1px solid #979797;
  border-radius: 7px 7px 7px 7px;
  width: 100%;
  background-color: #FFFFFF;
  margin: 10px;
}
.cardDesignDiv {
  display: flex;
  justify-content: space-between;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #CCCCCC;
  padding: 6px 20px 8px 20px;
  color: #454545;
  font: 'OpenSans';
  font-size: 12px;
  line-height: 14px;
  text-align: right;
}
.cardDesignDiv:last-child {
  border-bottom: none;
}

/* Card Design */
#addBulkPurchase {
  padding-bottom: 50px;
}
#addBulkPurchase #cardMapping {
  display: flex;
  justify-content: center;
  /* padding: auto; */
  flex-wrap: wrap;
}
#addBulkPurchase #cardMapping .cardDesign {
  margin: auto 8px 10px;
  border-radius: 4% / 6.3%!important;
  /* use aspect ratio 1.58 for card designs */
  width: 150px!important;
  height: 95px!important;
}
#addBulkPurchase #cardMapping .selectedCard {
  width: 155px!important;
  height: 98px!important;
  margin: auto 6px 8px;
}
#addBulkPurchase form .field {
  display: flex;
  align-items: center;
}
#addBulkPurchase form .react-datepicker-wrapper div input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
#addBulkPurchase .billingSelectLabel {
  background-color: rgb(232, 232, 232);
  border-radius: 4px 0px 0px 4px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: bold;
  padding: .70em .833em;
  height: 38px;
}

#addBulkPurchase .billingDropdown {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* BALANCE INQUIRIES */
.inquiryFilterContainer {
  background-color: #F9F9F9;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  margin: auto 30px;
}
.filterDateSearchContainer {
  display: flex;
  margin-bottom: 15px;
}
.filterDateContainer {
  display: flex;
  margin-right: 10px;
}
.inquiryFilterContainer .buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inquiryFilterContainer .buttons button i {
  margin-top: -3px!important;
}
.inquiryFilterContainer .buttons .activeFilter {
  background-color: #faf5f1;
  color: #000;
}
.inquiryFilterContainer .buttons .inactiveFilter {
  background-color: #fff;
  color: #b8b4b2;
}
.inquiryFilterContainer .buttons .button {
  border: 1px solid #E5E5E5;
  font-family: Lato;
  font-size: 14px;
  display: flex;
  height: 36px;
}
.inquiryViewHeader {
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  padding: 10px 17px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}


/* MISSING CONTENT */
.missingContent {
  margin-top: 0px;
  background-color: #f5f5f5!important;
}
.missingContent h5 {
  margin-bottom: 0px!important;
  color: #a3a3a3!important;
}
.missingContentDiv {
  display: flex;
  flex-wrap: wrap;
}
.missingContentDiv h6 {
  margin: 0px 8px 0px 0px!important;
  font-size: 13px;
  font-weight: normal;
  color: #a3a3a3;
}
.missingContentDiv h6::before {
  content: "- "
}


@media screen and (min-width: 800px) {
  #cardMapping .cardDesign {
    /* use aspect ratio 1.58 for card designs */
    width: 240px!important;
    height: 151px!important;
  }
  #cardMapping .selectedCard {
    width: 247px!important;
    height: 156!important;
    margin: auto 4px 8px;
  }
  .cardDesign {
    /* use aspect ratio 1.58 for card designs */
    width: 240px!important;
    height: 151px!important;
  }
}
@media screen and (max-width: 1170px) {
  .cardContainer {
    width: 100%!important;
    max-width: 1000px;
  }
}
