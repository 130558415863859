.uploadDiv {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
}



.uploadDiv .uploadHeader {
    margin-right: 130px;
    border-bottom: 2px solid #aaa;
}
.uploadDiv .uploadHeader h2 {
    color: #aaa;
    font-weight: normal;
}



.uploadDiv .templateDiv {
    margin-top: 20px;
}
.uploadDiv .templateDiv a {
    margin-left: 10px;
    color: #fff;
    background-color: #5bb5ff;
}
.uploadDiv .templateDiv a:hover {
    background-color: #5ac8fa;
    color: #f9f9f9;
}
.uploadDiv .templateDiv button {
    margin-left: 10px;
    color: #fff;
    background-color: #5bb5ff;
}
.uploadDiv .templateDiv button:hover {
    background-color: #5ac8fa;
    color: #f9f9f9;
}
.uploadDiv .templateDiv .spreadsheetTable {
    margin-top: 20px;
}
.uploadDiv .templateDiv .spreadsheetTable h5 {
    font-weight: normal;
    font-size: 12px;
    color: #777;
}
.uploadDiv .templateDiv .spreadsheetTable table {

}
.uploadDiv .templateDiv .spreadsheetTable table thead {

}
.uploadDiv .templateDiv .spreadsheetTable table thead tr {

}
.uploadDiv .templateDiv .spreadsheetTable table thead tr th {
    background-color: #f9f9f9;
    color: #00b5ad;
}


.uploadDiv .templateDiv .uploadButtonDiv .selectionConfirm {
    margin-left: 10px;
    margin-bottom: 0px;
    margin-top: 20px;
    text-align: center;
}
.uploadDiv .templateDiv .uploadButtonDiv .selectionConfirm h5 {
    font-weight: normal;
    color: #777;
    margin-top: 5px;
}
.uploadDiv .templateDiv .uploadButtonDiv button {
    margin-top: 15px;
}
.uploadDiv .templateDiv .uploadButtonDiv input {
    display: none;
}
.uploadDiv .templateDiv .uploadButtonDiv h5 {
    font-weight: normal;
    color: #777;
}




.uploadDiv .buttonDiv {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.uploadDiv .buttonDiv .next {
    background-color: #5bb5ff;
    color: #fff;
    margin-left: 25px;
}
/*.uploadDiv .buttonDiv .nextButtonHidden {*/
/*    display: none;*/
/*}*/
