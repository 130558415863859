.summaryDiv {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
}



.summaryDiv .summaryHeader {
    margin-right: 130px;
    border-bottom: 2px solid #aaa;
}
.summaryDiv .summaryHeader h2 {
    color: #aaa;
    font-weight: normal;
}



.summaryDiv .importSummaryDiv {
    margin-top: 20px;
}
.summaryDiv .importSummaryDiv h5 {
    font-weight: normal;
    font-size: 15px;
    color: #777;
}
.summaryDiv .importSummaryDiv .confirmation {
    display: flex;
    border: 1px solid #00b5ad;
    background-color: #f9f9f9;
    margin: 0 10px;
}
.summaryDiv .importSummaryDiv .confirmation h5 {
    margin-top: 0;
    margin-left: 10px;
}



.summaryDiv .buttonDiv {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.summaryDiv .buttonDiv .next {
    background-color: #00b5ad;
    color: #fff;
    margin-left: 25px;
}
