.matchDiv {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
}


.matchDiv .matchHeader {
    margin-right: 130px;
    border-bottom: 2px solid #aaa;
}
.matchDiv .matchHeader h2 {
    color: #aaa;
    font-weight: normal;
}



.matchDiv .matchFields {
    margin-top: 20px;
    width: 100%;
}
.matchDiv .matchFields h5 {
    font-weight: normal;
    font-size: 12px;
    color: #777;
}
body > div.ui.page.modals.dimmer.transition.visible.active > div > div > div.matchDiv > div.matchFields > h5:nth-child(2) {
    margin-top: 0;
}
.matchDiv .matchFields .inputs {
    width: 100%;
    display: flex;

}
.matchDiv .matchFields .inputs form {
    width: 100%;
}
.matchDiv .matchFields .inputs form .formGroup {
    display: flex;
    justify-content: space-between;
}
.matchDiv .matchFields .inputs .field {
    width: 30%;
}
.matchDiv .matchFields .inputs .field label {
    /*margin: 0 auto;*/
    color: #00b5ad;
}
.matchDiv .matchFields .segment {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid red;
}
.matchDiv .matchFields .segment i {
    margin: 0 2px 0 2px;
}
.matchDiv .matchFields .segment h4 {
    color: #777;
    text-align: center;
    margin: 0px;
}
.matchDiv .matchFields .previewTableContainer h5 {
    margin-bottom: 3px;
}
.matchDiv .matchFields .matchPreview {
    margin-top: 10px;
    max-height: 300px;
    overflow-y: scroll;
}
.matchDiv .matchFields .matchPreview table thead tr th {
    background-color: #f9f9f9;
    color: #00b5ad;
}
.matchDiv .matchFields .matchPreview table tbody tr td {
    color: #777;
}



.matchDiv .buttonDiv {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.matchDiv .buttonDiv .next {
    background-color: #00b5ad;
    color: #fff;
    margin-left: 25px;
}
